@media print {
  @page { 
    /*size: landscape; */
    margin: 50px !important;
  }
}

.daterangepicker {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", 
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 10px;
  box-shadow: 4px 4px 4px 1px #c4c4c4;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #F7DF36;
  color: #181714;
}
.daterangepicker .active.end-date.in-range.available{
  background-color: #F7DF36;
  color: #181714;
}
.daterangepicker .active.end-date.in-range.available.off{
  background-color: #d6d6d6;
  color: #181714;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #ffea66;
}
.daterangepicker td.in-range {
  background-color: #dcf0ff;
}
.btn-primary {
  background-color: #F7DF36;
  border-color: #F7DF36;
  color: #181714;
}
div[role="tooltip"] {
  z-index: 1400;
}
.daterangepicker .ranges li {
  color: #666666;
  font-size: 14px;
  padding: 15px;
}
.daterangepicker .ranges li.active {
  box-shadow: 0px 2px 3px -1px #c4c4c4;
  border-left: 6px solid #F7DF36;
  background-color: #F6F7F9;
  color: #181714;
  font-weight: bolder;
}
.next.available, .prev.available {
  background-color: #F6F7F9;
  padding: 5px 15px;
  border-radius: 5px !important;
}
.daterangepicker th.month {
  font-size: 14px;
  font-weight: bolder;
  color: #181714;
}
.daterangepicker td.in-range {
  background-color: #F6F7F9;
}
.daterangepicker .drp-buttons .btn.cancelBtn {
  background-color: #DADADA;
  padding: 5px 20px;
  color: #666666;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
}
.daterangepicker .drp-buttons .btn.cancelBtn:hover {
  background-color: #b9b9b9;
}
.daterangepicker .drp-buttons .btn.applyBtn {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: normal;
  border-radius: 8px;
  font-weight: bold;
}
.daterangepicker .drp-buttons .btn.applyBtn:hover {
  background-color: #f3e891;
  border-color: #F7DF36;
  color: #181714;
}
.daterangepicker.drp-selected {
  color: #181714;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect{
  border-radius: 5px;
}
.daterangepicker .drp-selected {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.swalAlert{ 
  z-index: 9999 !important;
}

.react-datepicker-wrapper input, .react-datepicker {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", 
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.react-datepicker-wrapper {
  width: 100%;
  min-height: 40px;
}
.react-datepicker-wrapper input {
  border: none;
  font-size: 14px;
  color: #808080;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.react-datepicker__month-text {
  text-transform: capitalize !important;
  padding: 5px 15px 5px 10px !important;
  font-size: 13px;
}
.react-datepicker__month-text--keyboard-selected {
  background-color: #f7df36 !important;
  color: #181714 !important;
  font-weight: bold !important;
}
.react-datepicker__header {
  background-color: white !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 40px;
  padding-top: 10px;
}
.react-datepicker__navigation--next {
  background-color: #F6F7F9 !important;
}
.react-datepicker__month .react-datepicker__month-text, 
.react-datepicker__month .react-datepicker__quarter-text {
  width: 4.6rem !important;
}
.react-datepicker__navigation {
  color: #fff !important;
  border: solid #000 !important;
  border-width: 0 2px 2px 0 !important;
  border-radius: 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  margin-top: 5px;
}
.react-datepicker__navigation--next {
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
}
.react-datepicker__navigation--previous {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg)
}
.react-datepicker__triangle {
  left: 50% !important;
  border-bottom-color: white !important;
}
.react-datepicker-wrapper input:focus-visible {
  border: none !important;
}